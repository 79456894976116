import { motion } from 'framer-motion';

const Path = (props: any) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#ffffff"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }: { toggle: () => void }) => (
  <div
    onClick={toggle}
    style={{
      background: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <svg width="20" height="20" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: 'M 2 2 L 20 2' },
          open: { d: 'M 3 22 L 17 3', stroke: '#975AFF' },
        }}
      />
      <Path
        d="M 2 12 L 20 12"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 21 L 20 21' },
          open: { d: 'M 3 3 L 17 22', stroke: '#975AFF' },
        }}
      />
    </svg>
  </div>
);
