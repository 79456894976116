import { motion, useCycle } from 'framer-motion';
import { MenuToggle } from '../MenuToggle';
import './header.css';

const logo = ['e', 'a', 's', 'y', 'm', 'o', 'n', 'e', 'y'];
const navItems = ['Home', 'About', 'Privacy', 'Get APP'];

const Header = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <motion.header
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      className="header"
    >
      <div className="header_wrapper">
        <div className="header_content header_content__left">
          <h1 className="logo_title">
            {logo.map((item, i) => (
              <motion.p
                key={i}
                animate={{
                  scale: [1, 1.5, 1],
                  color: ['#fff', '#975AFF', '#fff'],
                }}
                transition={{
                  duration: 0.3,
                  type: 'spring',
                  delay: 0.1 * (i + 1),
                }}
              >
                {item}
              </motion.p>
            ))}
          </h1>
        </div>
        <div className="header_content header_content__right">
          <nav className="menu__items">
            {navItems.map((item, i) => (
              <motion.li
                key={i}
                variants={{
                  closed: { y: 100, opacity: 0 },
                  open: { y: 0, opacity: 1 },
                }}
                transition={{
                  duration: 0.6,
                  type: 'spring',
                  delay: 0.05 * (i + 1),
                }}
              >
                <a href={`/${item}`}>{item}</a>
              </motion.li>
            ))}
          </nav>
          <MenuToggle toggle={() => toggleOpen()} />
        </div>
      </div>
    </motion.header>
  );
};

export default Header;
