import './style.css';
import { motion, AnimatePresence, useIsPresent } from 'framer-motion';
import { useEffect } from 'react';

const Block2 = () => {
  const isPresent = useIsPresent();

  useEffect(() => {
    !isPresent && console.log("I've been removed!");
  }, [isPresent]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 0.5, type: 'spring' }}
        className="container"
      >
        <div className="content">
          <div className="textWrapper">
            <h2 className="title">
              Зарабатывайте на просмотре популярной рекламы от наших парнеров
            </h2>
          </div>
          <div className="image_content">
            <img
              src="./images/Phone.png"
              alt="phone"
              className="image image1"
            />
            <img
              src="./images/Phone.png"
              alt="phone"
              className="image image2"
            />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Block2;
