import { useEffect, useState } from 'react';
import './bgLines.css';

const BgLines = () => {
  const [arr, setArr] = useState<number[] | null>();

  const generateRandArr = () =>
    Array.from({ length: 10 }, () => Math.floor(Math.random() * 10) + 1);

  useEffect(() => {
    const rArr = generateRandArr();
    setArr(rArr);
  }, []);

  return (
    <div className="bg">
      {arr?.map((item, index) => (
        <div
          key={index}
          style={{ animationDelay: `${item}00ms` }}
          className="bg_line"
        ></div>
      ))}
    </div>
  );
};

export default BgLines;
