import './style.css';

const Slider = ({
  length,
  activeIndex,
}: {
  length: any[];
  activeIndex: number;
}) => {
  return (
    <div className="slider">
      {length.map((_, i) => (
        <div
          key={i}
          className={`dot ${i === activeIndex ? 'dot_active' : ''}`}
        ></div>
      ))}
    </div>
  );
};

export default Slider;
