import './style.css';
import { motion, AnimatePresence, useIsPresent } from 'framer-motion';
import { useEffect } from 'react';
import { lineAnimation } from '../../animations';

const Block1 = () => {
  const isPresent = useIsPresent();

  useEffect(() => {
    !isPresent && console.log("I've been removed!");
  }, [isPresent]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, type: 'spring' }}
        className="container"
      >
        <div className="content">
          <div className="textWrapper">
            <motion.h2
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, type: 'spring', delay: 0.2 }}
              className="title"
            >
              Мобильное приложение EASYMONEY
            </motion.h2>
            <motion.h3
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, type: 'spring', delay: 0.3 }}
              className="subtitle"
            >
              Зарабатывай на просмотре рекламы и ответах на вопросы !
            </motion.h3>
            <div style={{ display: 'flex', gap: '20px', margin: '40px 0 0 0' }}>
              <motion.a
                whileHover={{ scale: 1.05 }}
                href="https://play.google.com/store/apps?hl=ru&gl=US"
                target="_blank"
              >
                <motion.img
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, type: 'spring', delay: 0.4 }}
                  src="https://static.cdninstagram.com/rsrc.php/v3/yr/r/fDjwyLC88oO.png"
                  alt=""
                  className="store"
                />
              </motion.a>
              <motion.a
                whileHover={{ scale: 1.05 }}
                href="https://www.apple.com/ru/app-store/"
                target="_blank"
              >
                <motion.img
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, type: 'spring', delay: 0.5 }}
                  src="https://static.cdninstagram.com/rsrc.php/v3/y9/r/E_2mQvC3HG-.png"
                  alt=""
                  className="store"
                />
              </motion.a>
            </div>
          </div>
          <div className="image_content">
            <motion.img
              initial={{ scale: 1, rotate: -20 }}
              animate={{
                scale: 1,
                rotate: -20,
                y: [0, -40, 0],
              }}
              transition={{ duration: 3, repeat: Infinity }}
              src="./images/Phone.png"
              alt="phone"
              className="image image1"
            />
            <motion.img
              initial={{ rotate: 10 }}
              animate={{
                rotate: 10,
                y: [0, -40, 0],
              }}
              transition={{ duration: 3, repeat: Infinity, delay: 0.3 }}
              src="./images/Phone.png"
              alt="phone"
              className="image image2"
            />
          </div>
        </div>
      </motion.div>
      <motion.svg
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.5 }}
        className="block1__svg"
        viewBox="0 0 1440 782"
        fill="none"
      >
        <motion.path
          animate={lineAnimation}
          transition={{
            repeat: Infinity,
            duration: 3,
            type: 'keyframes',
          }}
          stroke="url(#paint0_linear_678_24614)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_678_24614"
            x1="1482.35"
            y1="-37.9968"
            x2="-92.3624"
            y2="326.28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF59F8" stopOpacity="0" />
            <stop offset="0.345487" stopColor="#AE72FF" />
            <stop offset="0.491082" stopColor="#90E9ED" />
            <stop offset="0.683074" stopColor="#5CD167" />
            <stop offset="0.99968" stopColor="#FFEE00" stopOpacity="0" />
          </linearGradient>
        </defs>
      </motion.svg>
    </>
  );
};

export default Block1;
