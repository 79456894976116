import './App.css';
import BgLines from './components/BgLines';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import { useState } from 'react';
import Header from './components/Header';
import Block1 from './components/Block1';
import Slider from './components/Slider';
import Block2 from './components/Block2';

const App = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const content = [<Block1 />, <Block2 />, '3', '4'];

  const nextIndex = () => {
    if (currentIndex === content.length - 1) {
      return setCurrentIndex(content.length - 1);
    }
    return setCurrentIndex(currentIndex + 1);
  };
  const prevIndex = () => {
    if (currentIndex === 0) {
      return setCurrentIndex(0);
    }
    return setCurrentIndex(currentIndex - 1);
  };

  return (
    <>
      <BgLines />

      <ReactScrollWheelHandler
        upHandler={prevIndex}
        downHandler={nextIndex}
        className="i"
      >
        <Header />
        <Slider length={content} activeIndex={currentIndex} />
        {content[currentIndex]}
      </ReactScrollWheelHandler>
    </>
  );
};

export default App;
